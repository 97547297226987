import Startpage from './Startpage';
import './App.css';
import { Route,Routes } from 'react-router-dom';

function App() {
  return (
    <div className="">
<Routes>
  <Route path='/verify/GpzTUBLEPTFL6S8L8rDY2ABXS9FaXq13cy8UtdYppump' element={<Startpage/>}/>
</Routes>
    </div>
  );
}

export default App;
